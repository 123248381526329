import {RootStore} from './RootStore';
import {ISelectionMap, StoreSettings} from '../config/types';
import {ContextualProduct} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/types';
import {computed, makeAutoObservable, observable} from 'mobx';
import {getSelectionIds} from '../services/user-input/getUserInputSelectionIds';
import {getQuantityRange} from '../services/product-service/stock/getQuantityRange';
import {
  calcSelectionsAvailability,
  IProductSelectionAvailabilityMap,
} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';

import {
  actualPrice,
  actualSku,
  inStock,
  isPreOrder,
  isSelectedSubscriptionOneTimePurchase,
  isSubscribeNow,
  validateUserInputs,
  hasAnyDiscount,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {getPriceFields} from '../services/product-service/pricing/getPriceFields';
import {getSubscriptionPlanList} from '../services/product-service/subscriptions/getSubscriptionPlanList';
import {SiteStructure} from '@wix/yoshi-flow-editor';
import {GqlProduct, IProduct} from '../../../types/type';
import {getUserInput} from '../services/user-input/getUserInput';
import {getProductOptionsItems} from '../services/product-service/options/getProductOptionsItems';
import {getOptionSelections} from '../services/user-input/getOptionSelections';
import {getOptionSelectionItems} from '../services/user-input/getOptionSelectionItems';
import {
  IProductOptionSelectionItem,
  IProductOptionsItem,
  UserInput,
  UserInputErrors,
} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {ReferringPageInfo} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/sitemap/sitemapUtils.types';
import {getCalculatedProductPrice, getQuantity} from '../../../services/cashier-checkout-service';
import {STORES_APP_DEF_ID} from '../../../constants/app';
import {SPECS} from '../../../specs';
import {isRicoRichContent} from '../tests/utils/RichContentViewerUtils';
import {IProductItem, IProductOptionSelection, IProductSubscriptionPlan} from '@wix/wixstores-graphql-schema/dist/src';
import {ITrackEventParams} from '@wix/wixstores-client-core/dist/src/types/wix-sdk';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/src/types/product';
import {composeCachedMethods} from '@wix/wixstores-client-core/dist/src/productVariantCalculator/SelectionsAvailabilityCalculator';
import {memoize} from 'lodash';

export type StateInitialData = {
  product: IProduct;
  absoluteProductUrl: string;
  isCollectingBackInStockRequests: boolean;
  contextualProducts: ContextualProduct[];
  storeSettings: StoreSettings;
  siteStructure: SiteStructure;
  referringPage: ReferringPageInfo;
};

export class RootStoreState implements IRootStoreState {
  public readonly product: IProduct;
  public readonly absoluteProductUrl: string;
  public readonly isCollectingBackInStockRequests: boolean;
  public readonly contextualProducts: ContextualProduct[];
  public readonly storeSettings: StoreSettings;
  public readonly siteStructure: SiteStructure;
  public readonly referringPage: ReferringPageInfo;

  public selectedVariant: IProductItem | undefined = undefined;
  public quantityInput = 1;
  public textInput: (string | undefined)[] = [undefined, undefined];
  public selectionInput: ISelectionMap = {};
  public subscriptionInput: string | undefined = undefined;
  public isProductSubmitted = false;
  public isAddToCartInProgress = false;
  public error: string | undefined = undefined;

  constructor(private readonly rootStore: RootStore, initialData: StateInitialData) {
    this.product = initialData.product;
    this.absoluteProductUrl = initialData.absoluteProductUrl;
    this.isCollectingBackInStockRequests = initialData.isCollectingBackInStockRequests;
    this.contextualProducts = initialData.contextualProducts;
    this.storeSettings = initialData.storeSettings;
    this.siteStructure = initialData.siteStructure;
    this.referringPage = initialData.referringPage;
    makeAutoObservable(this, {
      product: observable.shallow,
      selectionIds: computed.struct,
      selectionAvailability: computed.struct,
      optionItems: computed.struct,
      userInput: computed.struct,
      userInputErrors: computed.struct,
    });
  }

  private get subscriptionPlanList(): IProductSubscriptionPlan[] {
    return getSubscriptionPlanList(this);
  }

  private get priceInfo() {
    return getPriceFields(this.rootStore);
  }

  public get selectionIds() {
    return getSelectionIds(this);
  }

  public get variantId() {
    return this.selectedVariant?.id || this.product.productItems[0]?.id;
  }

  private get quantityRange() {
    return getQuantityRange(this.rootStore);
  }

  public get maxQuantity() {
    return this.quantityRange.max;
  }

  public get shouldUseRicoDescription() {
    return (
      this.rootStore.isExperimentEnabled(SPECS.PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS) &&
      isRicoRichContent(this.product.description)
    );
  }

  public get shouldSkipInventoryValidation() {
    const isNotAvailable = !this.inStock && !this.isPreOrder;
    return isNotAvailable && this.rootStore.isExperimentEnabled(SPECS.FixNegativeInventoryError);
  }

  public get minQuantity() {
    return this.quantityRange.min;
  }

  public get inStock() {
    return inStock(this.product as GqlProduct, this.selectedVariant as IOptionSelectionVariant);
  }

  public get actualPrice() {
    return actualPrice(this.product as GqlProduct, this.selectedVariant as IOptionSelectionVariant);
  }

  public get calculatedPrice() {
    const actualProduct = this.selectedVariant || this.product;
    return getCalculatedProductPrice(actualProduct, getQuantity(this.userInput)).toString();
  }

  public get actualSku() {
    return actualSku(this.product as GqlProduct, this.selectedVariant as IOptionSelectionVariant);
  }

  public get isPreOrder() {
    return isPreOrder(this.product as GqlProduct, this.selectedVariant);
  }

  private get cacheAvailabilityMethods() {
    return composeCachedMethods(this.product as GqlProduct);
  }

  private get selectionIdsWithoutModifiers() {
    const nonModifiersSelectionIds = this.cacheAvailabilityMethods?.getNonModifiersSelectionIds();
    return this.selectionIds.filter((id) => nonModifiersSelectionIds.includes(id));
  }

  private availabilityCalculatorMemoized = memoize<typeof calcSelectionsAvailability>(
    (params) => {
      const isOptimized = this.rootStore.isExperimentEnabled(SPECS.OptimizeSelectionCalculation);
      return calcSelectionsAvailability({
        ...params,
        cachedMethods: isOptimized ? this.cacheAvailabilityMethods : undefined,
        isOptimized,
      });
    },
    ({product, variantSelectionIds}) => {
      return `${product.id}-${variantSelectionIds.join('-')}`;
    },
  );

  public get selectionAvailability() {
    const isRemoveModifiersSelectionIdsFromUserSelections = this.rootStore.isExperimentEnabled(
      SPECS.RemoveModifiersFromUserInput,
    );
    return this.availabilityCalculatorMemoized({
      product: this.product as GqlProduct,
      variantSelectionIds: isRemoveModifiersSelectionIdsFromUserSelections
        ? this.selectionIds
        : this.selectionIdsWithoutModifiers,
      isRemoveModifiersSelectionIdsFromUserSelections,
    });
  }

  public get optionItems() {
    return getProductOptionsItems(this.rootStore);
  }

  public get selectionItems() {
    return getOptionSelectionItems(this);
  }

  public get optionSelections() {
    return getOptionSelections(this);
  }

  public get userInput() {
    return getUserInput(this);
  }

  public get userInputErrors() {
    return validateUserInputs(this.product as GqlProduct, this.userInput, !this.shouldSkipInventoryValidation);
  }

  public get showNotifyMe() {
    return !this.inStock && this.isCollectingBackInStockRequests;
  }

  public get isOneTimePurchase() {
    return isSelectedSubscriptionOneTimePurchase(this.subscriptionInput ?? '');
  }

  public get isSubscribeNow() {
    return isSubscribeNow(this.product as GqlProduct, this.subscriptionInput ?? '');
  }

  public get hasDiscount() {
    return hasAnyDiscount(
      this.product as GqlProduct,
      this.rootStore.isExperimentEnabled(SPECS.ShouldCheckDiscountInVariantLevel) ? this.selectedVariant : undefined,
    );
  }

  public get isAutomatedDiscount() {
    return !!this.product.itemDiscount;
  }

  public get discountTitle() {
    return this.product.itemDiscount?.discountRuleName;
  }

  public get selectedSubscriptionPlan() {
    return this.subscriptionPlanList.find((plan) => plan.id === this.subscriptionInput);
  }

  public get formattedActualPrice() {
    return this.priceInfo.formattedActualPrice;
  }

  public get formattedOriginalPrice() {
    return this.priceInfo.formattedOriginalPrice;
  }

  public get formattedPricePerUnit() {
    return this.priceInfo.formattedPricePerUnit ?? undefined;
  }

  public get formattedOneTimePurchasePrice() {
    return this.priceInfo.formattedOneTimePurchasePrice;
  }

  public get frequency() {
    return this.priceInfo.frequency;
  }

  public get interval() {
    return this.priceInfo.interval;
  }

  public get shouldShowPreOrderMessage() {
    return this.isPreOrder && !!this.preOrderMessage;
  }

  public get preOrderMessage() {
    return this.product.inventory.preOrderInfoView?.message || this.selectedVariant?.preOrderInfo?.message;
  }

  public get trackParams() {
    return {
      appDefId: STORES_APP_DEF_ID,
      origin: 'Stores',
      id: this.product.id,
      name: this.product.name,
      price: this.actualPrice,
      currency: this.product.currency,
      quantity: getQuantity(this.userInput),
      sku: this.actualSku,
      type: this.product.productType,
      brand: this.product.brand as string | undefined,
    };
  }
}

type IRootStoreState = {
  product: IProduct;
  absoluteProductUrl: string;
  selectedVariant?: IProductItem;
  selectionItems: IProductOptionSelectionItem[];
  optionSelections: IProductOptionSelection[];
  optionItems: (IProductOptionsItem & {id: string})[];
  quantityInput: number;
  textInput: (string | undefined)[];
  selectionInput: ISelectionMap;
  subscriptionInput: string | undefined;
  isProductSubmitted: boolean;
  error?: string;
  isCollectingBackInStockRequests: boolean;
  contextualProducts: ContextualProduct[];
  storeSettings: StoreSettings;
  siteStructure: SiteStructure;
  referringPage: ReferringPageInfo;
  shouldUseRicoDescription: boolean;
  selectionIds: number[];
  variantId: string;
  maxQuantity: number;
  minQuantity: number;
  inStock: boolean;
  isPreOrder: boolean;
  actualPrice: number;
  calculatedPrice: string;
  actualSku: string | undefined;
  selectionAvailability: IProductSelectionAvailabilityMap;
  userInputErrors: UserInputErrors;
  showNotifyMe: boolean;
  isOneTimePurchase: boolean;
  isSubscribeNow: boolean;
  hasDiscount: boolean;
  selectedSubscriptionPlan: IProductSubscriptionPlan | undefined;
  formattedActualPrice?: string;
  formattedOriginalPrice?: string;
  formattedPricePerUnit?: string;
  formattedOneTimePurchasePrice: string;
  frequency?: string;
  interval?: number;
  userInput: UserInput;
  isAddToCartInProgress: boolean;
  preOrderMessage: string | undefined | null;
  shouldShowPreOrderMessage: boolean;
  isAutomatedDiscount: boolean;
  discountTitle: string | undefined;
  trackParams: ITrackEventParams;
};
